<!-- @format -->
<template>
  <div>
    <transition
      v-if="!isModalPrerendered"
      name="fade"
      @before-enter="onEnter"
      @after-enter="emitOpened"
      @before-leave="onLeave"
      @after-leave="emitClosed"
    >
      <div
        v-if="isModalOpened[name]"
        :class="[
          'c-overlay',
          { noPadding: hasNoPadding },
          { noPaddingNoOverflow: hasNoPaddingAndNoOverflow },
          { noPaddingNoOverflowMobile: hasNoPaddingAndNoOverflowOnMobile },
        ]"
        @click.self="hideModal(name)"
      >
        <div
          :class="[`c-modal c-modal--${name}`]"
          :style="{ width: width, height: height }"
        >
          <button class="c-modal__close" @click="hideModal(name)">
            <slot name="close-icon">
              <CloseIcon />
            </slot>
          </button>
          <slot />
        </div>
      </div>
    </transition>
    <div
      v-else
      v-show="isModalOpened[name]"
      :class="[
        'c-overlay',
        { noPadding: hasNoPadding },
        { noPaddingNoOverflow: hasNoPaddingAndNoOverflow },
        { noPaddingNoOverflowMobile: hasNoPaddingAndNoOverflowOnMobile },
      ]"
      @click.self="hidePrerenderedModal"
    >
      <div
        :class="[`c-modal c-modal--${name}`]"
        :style="{ width: width, height: height }"
      >
        <button class="c-modal__close" @click="hidePrerenderedModal">
          <slot name="close-icon">
            <CloseIcon />
          </slot>
        </button>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { MODAL_OPENED_QUERY_END } from '@/config'

import {
  addQueryParamToCurrentUrl,
  deleteQueryParamFromCurrentUrl,
} from '@/helpers/webApihelper'

import CloseIcon from '@/components/atoms/icons/Close'

export default {
  components: { CloseIcon },
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '650px',
    },
    height: {
      type: String,
      default: 'auto',
    },
    isModalPrerendered: {
      type: Boolean,
      default: false,
    },
    hasNoPadding: {
      type: Boolean,
      default: false,
    },
    hasNoPaddingAndNoOverflow: {
      type: Boolean,
      default: false,
    },
    hasNoPaddingAndNoOverflowOnMobile: {
      type: Boolean,
      default: false,
    },
    isCloseOnBackNavPrevented: Boolean,
  },
  emits: ['open', 'opened', 'close', 'closed'],
  computed: {
    ...mapState({
      isModalOpened: (state) => state.modal && state.modal.isModalOpened,
    }),
  },
  mounted() {
    if (!this.isCloseOnBackNavPrevented) {
      window.addEventListener('popstate', this.hideModalOnBackNav)
    }
  },
  beforeDestroy() {
    if (!this.isCloseOnBackNavPrevented) {
      window.removeEventListener('popstate', this.hideModalOnBackNav)
    }
  },
  destroyed() {
    const page = document.querySelector('html')
    page.classList.remove('has-overflow')
  },
  methods: {
    ...mapMutations({
      hideModal: 'modal/HIDE_MODAL',
    }),
    emitOpened() {
      this.$emit('opened')
    },
    emitClosed() {
      this.$emit('closed')
    },
    onEnter() {
      this.$emit('open')
      const page = document.querySelector('html')
      page.classList.add('has-overflow')
      if (!this.isCloseOnBackNavPrevented) {
        addQueryParamToCurrentUrl(`${this.name}${MODAL_OPENED_QUERY_END}`, '1')
      }
    },
    onLeave() {
      this.$emit('close')
      const page = document.querySelector('html')
      page.classList.remove('has-overflow')
      if (!this.isCloseOnBackNavPrevented) {
        deleteQueryParamFromCurrentUrl(`${this.name}${MODAL_OPENED_QUERY_END}`)
      }
    },
    hidePrerenderedModal() {
      this.hideModal(this.name)
      this.onLeave()
    },
    hideModalOnBackNav() {
      if (this.isModalOpened[this.name]) {
        if (this.isModalPrerendered) {
          this.hidePrerenderedModal()
        } else {
          this.hideModal(this.name)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-overlay {
  position: fixed;
  z-index: 50;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.85);
  overflow-y: auto;
}

.noPadding {
  padding: 0 !important;
}

.noPaddingNoOverflow {
  padding: 0 !important;
  overflow-y: hidden !important;
}

.noPaddingNoOverflowMobile {
  @include mq($until: tablet) {
    padding: 0 !important;
    overflow-y: hidden !important;
  }
}

.c-modal {
  $c: &;

  position: relative;
  z-index: 51;
  min-height: 300px;
  margin: auto;
  backdrop-filter: blur(20px);
  background-color: $white;
  text-align: left;

  @include mq($until: tablet) {
    max-width: 94%;
    min-height: auto;
  }

  &__close {
    position: absolute;
    z-index: 1;
    top: 2.3rem;
    right: 2.3rem;
    width: 2.2rem;
    height: 2.2rem;
    padding: 0;
    border: none;
    margin: 0;
    background-color: transparent;
    color: $grey-300;

    > svg {
      display: block;
      width: 100%;
      height: auto;
      fill: currentcolor;
    }

    @include mq($until: tablet) {
      top: 1.5rem;
      right: 1.5rem;
      width: 1.7rem;
      height: 1.7rem;
    }
  }

  &--forgotten {
    padding: 8rem 12.2rem 7.9rem 10.3rem;

    @include mq($until: tablet) {
      padding: 5.5rem 2.8rem;
    }
  }

  &--relayPoints {
    // stylelint-disable-next-line selector-class-pattern
    .c-modal__close {
      @include mq($until: tablet) {
        top: 1.7rem;
        right: auto;
        left: 2.1rem;
      }

      > svg {
        &:first-child {
          @include mq($until: tablet) {
            display: none;
          }
        }

        &:last-child {
          display: none;
          width: 1rem;
          height: auto;

          @include mq($until: tablet) {
            display: block;
          }
        }
      }
    }

    @include mq($until: tablet) {
      max-width: 100%;
      height: 100vh !important;
      padding: 0;
      margin: 0;
    }
  }

  &--relayPointSchedule {
    max-width: 100%;
    min-height: auto;
    padding: 2rem 1.9rem 3rem;
  }

  &--ratings {
    overflow: hidden;
    max-width: 100vw;
    padding: 4rem;
    margin: 0;
    overflow-y: auto;

    @include mq($until: tablet) {
      padding: 4rem 0;
    }
  }

  &--sizer {
    max-height: 100dvh;
    padding: 9.9rem 8.3rem 5.1rem 6.7rem;
    overflow-y: auto;

    &::after {
      display: block;
      clear: both;
      content: '';
    }

    #{$c}__close {
      right: 11.3rem;

      @include mq($until: tablet) {
        right: 1.5rem;
      }
    }

    @include mq($until: tablet) {
      padding: 4.6rem 0.7rem 3.7rem;
    }
  }

  &--address {
    @include mq($until: tablet) {
      width: 100% !important;
      max-width: 100%;
      margin: 0;

      // stylelint-disable-next-line selector-class-pattern
      > .c-modal__close {
        z-index: 2;
        top: 2rem;
        right: 1.3rem;
      }
    }
  }

  &--coordonnees {
    max-width: 58rem;
    padding: 2.2rem;

    // stylelint-disable-next-line selector-class-pattern
    > .c-modal__close {
      top: 2.4rem;
      right: 2.4rem;

      @include mq($until: tablet) {
        top: 2rem;
        right: 1.3rem;
      }
    }

    @include mq($until: tablet) {
      max-width: 100%;
      height: 100%;
      padding: 0 1.1rem;
    }
  }

  &--phone {
    // stylelint-disable-next-line selector-class-pattern
    > .c-modal__close {
      top: 3rem;
      right: 3rem;
    }
  }

  &--h {
    @include mq($until: tablet) {
      padding: 2rem 1.9rem 3rem;

      // stylelint-disable-next-line selector-class-pattern
      > .c-modal__close {
        top: 2rem;
        right: 3rem;
      }
    }
  }

  &--refund {
    padding: 8.1rem 5rem 8.9rem;

    // stylelint-disable-next-line selector-class-pattern
    > .c-modal__close {
      top: 3rem;
      right: 3rem;
    }

    @include mq($until: tablet) {
      padding: 4.8rem 2.6rem 5.2rem;

      // stylelint-disable-next-line selector-class-pattern
      > .c-modal__close {
        top: 1.5rem;
        right: 1.5rem;
      }
    }
  }

  &--outlet {
    margin: 0 auto;
  }

  &--connect {
    min-height: 1rem;

    @include mq($until: tablet) {
      max-width: 100%;
    }

    // stylelint-disable-next-line selector-class-pattern
    > .c-modal__close {
      top: 1.5rem;
      right: 1.5rem;
      width: 0.9rem;
      height: 0.9rem;

      @include mq($until: tablet) {
        top: 1.5rem;
        right: 1.5rem;
        width: 1rem;
        height: 1rem;
      }

      > svg {
        width: 100%;
        height: auto;
      }
    }
  }

  &::v-deep #page-cms {
    h1 {
      margin: 0;
      margin-bottom: 3.9rem;
      color: $grey-300;
      font-size: 1.8rem;
      font-weight: 900;
    }
  }
}
</style>
