export const state = () => ({
  isModalOpened: {}
})

export const mutations = {
  SHOW_MODAL (state, name) {
    state.isModalOpened = { ...state.isModalOpened, [name]: true}
  },
  HIDE_MODAL (state, name) {
    state.isModalOpened = { ...state.isModalOpened, [name]: false}
  },
}