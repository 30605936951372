export const SOCIAL_NETWORKS_LINKS = {
  laboutiqueofficielle: [
    { name: 'insta', link: 'https://instagram.com/laboutiqueofficielle/', icon: 'InstagramIcon' },
    { name: 'tiktok', link: 'https://www.tiktok.com/@laboutiqueofficielle', icon: 'TikTokIcon' },
    { name: 'facebook', link: 'https://www.facebook.com/laboutiqueofficielle', icon: 'FacebookIcon' },
    { name: 'youtube', link: 'https://www.youtube.com/user/LaBoutiqueOfficielle', icon: 'YoutubeIcon' }
  ],
  ryses_es: [
    { name: 'tiktok', link: 'https://www.tiktok.com/@ryses', icon: 'TikTokIcon' },
    { name: 'insta', link: 'https://www.instagram.com/ryses/', icon: 'InstagramIcon' },
    { name: 'facebook', link: 'https://www.facebook.com/rysesofficial', icon: 'FacebookIcon' }
  ],
  ryses_it: [
    { name: 'tiktok', link: 'https://www.tiktok.com/@ryses', icon: 'TikTokIcon' },
    { name: 'insta', link: 'https://www.instagram.com/ryses/', icon: 'InstagramIcon' },
    { name: 'facebook', link: 'https://www.facebook.com/rysesofficial', icon: 'FacebookIcon' }
  ],
  ryses_be: [
    { name: 'tiktok', link: 'https://www.tiktok.com/@ryses', icon: 'TikTokIcon' },
    { name: 'insta', link: 'https://www.instagram.com/ryses/', icon: 'InstagramIcon' },
    { name: 'facebook', link: 'https://www.facebook.com/rysesofficial', icon: 'FacebookIcon' }
  ],
  ryses_de: [
    { name: 'tiktok', link: 'https://www.tiktok.com/@ryses', icon: 'TikTokIcon' },
    { name: 'insta', link: 'https://www.instagram.com/ryses/', icon: 'InstagramIcon' },
    { name: 'facebook', link: 'https://www.facebook.com/rysesofficial', icon: 'FacebookIcon' }
  ],
  ryses_com: [
    { name: 'tiktok', link: 'https://www.tiktok.com/@ryses', icon: 'TikTokIcon' },
    { name: 'insta', link: 'https://www.instagram.com/ryses/', icon: 'InstagramIcon' },
    { name: 'facebook', link: 'https://www.facebook.com/rysesofficial', icon: 'FacebookIcon' }
  ]
}
