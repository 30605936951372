<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" :width="props.isSmall ? '10' : '24'" :height="props.isSmall ? '10' : '24'" viewBox="0 0 24 24">
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  }
}
</script>
