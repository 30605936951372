import merge from '~lodash.mergewith'
import { init } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

/* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
const DISABLED_INTEGRATION_KEYS = []

export function getConfig (ctx) {
  const config = {
    dsn:"https:\u002F\u002F182065ab0572494690f40b86ffd652be@o65549.ingest.sentry.io\u002F6365787",
    environment:"prod",
    release:"1.78.0",
    autoSessionTracking:false,
    ignoreErrors:["top.GLOBALS","originalCreateNotification","canvas.contentDocument","MyApp_RemoveAllHighlights","http:\u002F\u002Ftt.epicplay.com","Can't find variable: ZiteReader","jigsaw is not defined","ComboSearch is not defined","http:\u002F\u002Floading.retry.widdit.com\u002F","atomicFindClose","fb_xd_fragment","bmi_SafeAddOnload","EBCallBackMessageReceived","conduitPage","Script error.","Request has been terminated","Network Error"],
    ignoreUrls:[new RegExp("graph\\.facebook\\.com", "i"),new RegExp("connect\\.facebook\\.net\\\u002Fen_US\\\u002Fall\\.js", "i"),new RegExp("eatdifferent\\.com\\.woopra-ns\\.com", "i"),new RegExp("static\\.woopra\\.com\\\u002Fjs\\\u002Fwoopra\\.js", "i"),new RegExp("extensions\\\u002F", "i"),new RegExp("^chrome:\\\u002F\\\u002F", "i"),new RegExp("127\\.0\\.0\\.1:4001\\\u002Fisrunning", "i"),new RegExp("webappstoolbarba\\.texthelp\\.com\\\u002F", "i"),new RegExp("metrics\\.itunes\\.apple\\.com\\.edgesuite\\.net\\\u002F", "i")],
    allowUrls:[new RegExp("https:\\\u002F\\\u002Fwww\\.(laboutiqueofficielle|ryses-(es|it|pt|com|be|de))\\.local", ""),new RegExp("https:\\\u002F\\\u002Fwww\\.preprod\\.(laboutiqueofficielle|ryses)\\.(es|it|pt|com|be|de)", ""),new RegExp("https:\\\u002F\\\u002Fwww\\.(laboutiqueofficielle|ryses)\\.(es|it|pt|com|be|de)", "")],
    beforeSend:(event, hint) => {
        // Function brought by @jeengbe (https://gist.github.com/jeengbe/4bc86f05a41a1831e6abf2369579cc7a)
        function shouldIgnoreError(error, hint) {
          return isRecaptchaBadTimeoutRejection(error, hint) || isNetworkError(error) || isJavaExceptionError(error) || isGMOError(error) || isTransitionClassesError(error);
        }

        // https://github.com/getsentry/sentry-javascript/issues/2514
        function isRecaptchaBadTimeoutRejection(
        _,
        hint)
        {
          return hint.originalException === 'Timeout';
        }

        // REFACTOR: Too much responsibilities -> to refactor
        function isNetworkError(error) {
          const typeErrorFetchFailedValues = new Set([
          'Failed to fetch',
          'failed to fetch',
          'NetworkError when attempting to fetch resource.',
          'Load failed',
          'load failed']
          );

          const exceptionFromExtra = error.extra?.exception;

          if (exceptionFromExtra) {
            const isNetworkError = /Network Error/i.test(exceptionFromExtra.message) || exceptionFromExtra.code === 'ECONNABORTED';

            const { status, headers } = exceptionFromExtra.response || exceptionFromExtra;

            // When status is 500 and headers are null we consider the error as a network error
            return isNetworkError || status === 500 && headers === null;
          }

          const exception = error.exception?.values?.[0];
          const now = Date.now();

          if (
          exception?.type !== 'TypeError' || !typeErrorFetchFailedValues.has(exception.value))
          {
            return false;
          }

          if (!error.breadcrumbs) {
            return false;
          }

          // We go from the back since the last breadcrumb is most likely the erroneous one
          for (let i = error.breadcrumbs.length - 1; i >= 0; i--) {
            const breadcrumb = error.breadcrumbs[i];
            if (!breadcrumb) {continue;}

            // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
            if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > 5000) {
              break;
            }

            if (isErroneousBreadcrumb(breadcrumb)) {
              return true;
            }
          }

          return false;
        }

        function isErroneousBreadcrumb(breadcrumb) {
          if (
          breadcrumb.level !== 'error' ||
          breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch')
          {
            return false;
          }

          const url = breadcrumb.data?.url;
          const dataArgs = breadcrumb.data?.arguments;
          const stack = dataArgs?.length ? dataArgs[0].stack : undefined;

          if (!url && !stack) {return false;}

          const deniedUrlRegex = /(https?):\/\/(?:www\.)?(?:[a-zA-Z0-9-]+\.)?(clarity|abtasty|fitanalytics|criteo)\.[a-z]+(?:\/[^?#\s]*)?/;

          if (url) {
            return deniedUrlRegex.test(url);
          }

          const blacklistedScripts = [
          'fitanalytics',
          'abtasty',
          'clarity',
          'criteo'];

          return blacklistedScripts.some((script) => stack.includes(script));
        }

        function isJavaExceptionError(error) {
          const javaExceptionMessage = 'Java exception was raised during method invocation';
          const errorMessage = error.exception?.values?.[0]?.value || '';

          return errorMessage.includes(javaExceptionMessage);
        }

        function isGMOError(error) {
          const gmoRegex = /\b(gmo|gmo\.)\b/i;
          const errorMessage = error.exception?.values?.[0]?.value || '';
          const exceptionFromExtraMessage = error.extra?.exception?.message || '';

          return gmoRegex.test(errorMessage) || gmoRegex.test(exceptionFromExtraMessage);
        }

        function isTransitionClassesError(error) {
          const transitionClassesErrorMessage = '_transitionClasses';

          const errorMessage = error.exception?.values?.[0]?.value || '';
          const exceptionFromExtraMessage = error.extra?.exception?.message || '';

          return errorMessage.includes(transitionClassesErrorMessage) || exceptionFromExtraMessage.includes(transitionClassesErrorMessage);
        }

        const ignore = shouldIgnoreError(event, hint);

        return ignore ? null : event;
      },
  }

  const resolvedIntegrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
  ]

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
