<template>
  <div>
    <div class="c-newsletter-form">
      <div class="h3-like c-newsletter-form__title">
        {{ $t('components.molecules.footer.newsletterForm.title') }}
      </div>
      <form @submit="submit($event)">
        <ButtonWithInput reverse>
          <template #input>
            <input
              v-model.trim="email"
              type="email"
              :aria-label="$t('components.molecules.footer.newsletterForm.inputTitle')"
              :placeholder="$t('components.molecules.footer.newsletterForm.placeholder')"
              :title="$t('components.molecules.footer.newsletterForm.inputTitle')"
            >
          </template>
          <template #btn>
            <VButton
              type="submit"
              :title="$t('components.molecules.footer.newsletterForm.title')"
              :loading="loading"
              :disabled="!email"
              reverse
            >
              {{ $t('components.molecules.footer.newsletterForm.btn') }}
            </VButton>
          </template>
        </ButtonWithInput>
      </form>
    </div>
    <SimpleModal name="subscribe">
      <ModalBasicContent modal-name="subscribe">
        <template #title>
          {{ message }}
        </template>
        <template #button>
          {{ $t('components.molecules.footer.newsletterForm.modal.close') }}
        </template>
      </ModalBasicContent>
    </SimpleModal>
  </div>
</template>

<script>

import { mapMutations } from 'vuex'
import ButtonWithInput from '@/components/atoms/global/button-with-input/ButtonWithInput'
import SimpleModal from '@/components/atoms/modal/SimpleModal'
import ModalBasicContent from '@/components/molecules/modal/modal-content/ModalBasicContent'
import VButton from '@/components/atoms/global/button/VButton'
import { sendSentryMessage } from '@/helpers/errorHelper'

export default {
  components: {
    ButtonWithInput,
    SimpleModal,
    ModalBasicContent,
    VButton
  },
  data () {
    return {
      email: null,
      loading: false,
      message: null
    }
  },
  methods: {
    ...mapMutations({
      showModal: 'modal/SHOW_MODAL',
    }),
    async submit (evt) {
      evt.preventDefault()
      this.loading = true
      try {
        await this.$subscribeNewsletter(this.email)
        this.message = this.$t('components.molecules.footer.newsletterForm.modal.confirm')
        // this.$gtm.trackEvent({
        //   event: 'lbo_newsletter',
        //   subscription_status: 'subscribe'
        // })
      } catch (error) {
        if (error.response.status !== 422) {
          sendSentryMessage.bind(this, 'Unable to subscribe to newsletter', 'error', {
            email: this.email,
            exception: error.response ?? error
          })()
        } else {
          this.message = error.response.data[0].message
        }
      } finally {
        this.loading = false
        this.showModal('subscribe')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-newsletter-form {
  padding: 5rem 2rem;

  @include mq($until: tablet) {
    padding: 2.9rem 1.1rem 3.4rem;
  }

  background: $primary;
  color: $white;
  text-align: center;

  &__title {
    margin: 0 0 3rem;

    @include mq($until: tablet) {
      margin-bottom: 1.6rem;
    }
  }

  .c-btn {
    position: relative;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2.5rem;
    margin-top: -0.5rem;
    fill: currentcolor;
    transform: translateX(-50%);
  }

  .c-btn-with-input {
    max-width: 63rem;
    margin: auto;
  }
}
</style>
