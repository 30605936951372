/* @format */

/**
 * This method deletes a query parameter from the current url, and push/replace the new state in the history
 * @param {String} param
 * @param {Boolean} replaceState if we want to replace state in the history instead of pushing
 * @returns {void}
 */
export function deleteQueryParamFromCurrentUrl(param, replaceState = false) {
  if (!window) {
    throw new TypeError(
      'the deleteQueryParamFromCurrentUrl function cannot be used outside window and web api context',
    )
  }

  const url = new URL(window.location.href)
  url.searchParams.delete(param)
  if (replaceState) {
    window.history.replaceState(window.history.state, '', url.toString())
  } else {
    window.history.pushState(window.history.state, '', url.toString())
  }
}

/**
 * This method adds a query parameter in the current url, and push/replace the new state in the history
 * @param {String} param
 * @param {String} value value attributed to param
 * @param {Boolean} replaceState if we want to replace state in the history instead of pushing
 * @returns {void}
 */
export function addQueryParamToCurrentUrl(
  param,
  value = '',
  replaceState = false,
) {
  if (!window) {
    throw new TypeError(
      'the addQueryParamToCurrentUrl function cannot be used outside window and web api context',
    )
  }

  const url = new URL(window.location.href)

  if (url.searchParams.get(param) !== null) return

  url.searchParams.set(param, value)
  if (replaceState) {
    window.history.replaceState(history.state, '', url.toString())
  } else {
    window.history.pushState(history.state, '', url.toString())
  }
}
