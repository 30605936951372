<template>
  <div v-if="items" class="c-megamenu" :class="{'c-megamenu--full': full}">
    <div class="c-megamenu__main" :class="(nbColumns === 2) ? 'u-1/2' : (!brands ? 'u-' + nbColumns + '/5': 'u-4/5')">
      <div>
        <div class="c-megamenu-nav" :class="{'c-megamenu-nav--brands': brands}">
          <div v-for="(item, index) in items" :key="'megamenu-nav-'+ index" class="c-megamenu-nav__item">
            <nuxt-link
              v-if="item.url"
              :to="localePath(item.url.path)"
              class="c-megamenu-nav__title"
              @click.native="$emit('close', $event, item.url)"
            >
              {{ item.name }}
            </nuxt-link>
            <span
              v-else
              class="c-megamenu-nav__title"
            >
              {{ item.name }}
            </span>
            <div v-if="brands && item.menuSubSectionItems.length > 15" class="c-megamenu-nav__list-wrapper">
              <div class="c-megamenu-nav__list">
                <nuxt-link
                  v-for="(link, index2) in item.menuSubSectionItems.slice(0, 15)"
                  :key="'sub-menu-'+ index +'-'+ index2"
                  class="c-megamenu-nav__link"
                  :title="link.name"
                  :to="localePath(link.url)"
                  @click.native="$emit('close', $event, link.url)"
                >
                  {{ link.name }}
                  <Sticker v-if="link.label" :grey="link.label === 'TOP'">
                    {{ link.label }}
                  </Sticker>
                </nuxt-link>
              </div>
              <div class="c-megamenu-nav__list">
                <nuxt-link
                  v-for="(link, index2) in item.menuSubSectionItems.slice(15)"
                  :key="'sub-menu-'+ index +'-'+ index2"
                  class="c-megamenu-nav__link"
                  :title="link.name"
                  :to="localePath(link.url)"
                  @click.native="$emit('close', $event, link.url)"
                >
                  {{ link.name }}
                  <Sticker v-if="link.label" :grey="link.label === 'TOP'">
                    {{ link.label }}
                  </Sticker>
                </nuxt-link>
              </div>
            </div>
            <div v-else class="c-megamenu-nav__list">
              <nuxt-link
                v-for="(link, index2) in item.menuSubSectionItems"
                :key="'sub-menu-'+ index +'-'+ index2"
                class="c-megamenu-nav__link"
                :title="link.name"
                :to="localePath(link.url)"
                @click.native="$emit('close', $event, link.url)"
              >
                {{ link.name }}
                <Sticker v-if="link.label" :grey="link.label === 'TOP'">
                  {{ link.label }}
                </Sticker>
              </nuxt-link>
            </div>
          </div>
        </div>
        <div v-if="brands && url" class="c-megamenu-nav__wrapper">
          <VButton :to="localePath(url)" :href="url" type="link" large @click.native="$emit('close', $event, url)">
            {{ $t('components.molecules.header.megaMenu.allBrands') }}
          </VButton>
        </div>
      </div>
    </div>
    <div v-if="templates" class="c-megamenu__side" :class="(nbColumns === 2) ? 'u-1/2' :'u-1/5'">
      <div class="c-megamenu-products">
        <ul class="c-megamenu-products__list">
          <li
            v-for="template in templates"
            :key="template.slug"
            class="c-megamenu-products__item"
            :class="'u-1/'+ ((nbColumns > 2) ? '1':'2')"
          >
            <Category
              :link="template.link"
              :image="template.image"
              :title="template.title"
              :small="true"
              :promotion-data="{
                name: menuId + '_menu_template',
                slot: menuId + '_menu_template',
                version: templates[0].creativeVersion
              }"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Category from '@/components/atoms/global/category/Category'
import Sticker from '@/components/atoms/product/sticker/Sticker'
import VButton from '@/components/atoms/global/button/VButton'
export default {
  components: {
    Category,
    Sticker,
    VButton
  },
  props: {
    brands: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: null
    },
    templates: {
      type: Array,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    menuId: {
      type: String,
      default: null
    }
  },

  computed: {
    nbColumns () {
      return this.items.length
    },

    full () {
      return this.items.length >= 3
    }
  }
}
</script>

<style lang="scss" scoped>
.c-megamenu {
  $c: &;

  display: flex;
  width: 100%;
  max-width: 91.8rem;
  justify-content: center;
  padding: 2.4rem 0 1.8rem;
  margin: 0 auto;
  background-color: $white;

  &--full {
    max-width: 114.2rem;
  }

  &__main {
    padding-right: 1.1rem;
  }

  &__side {
    padding-left: 1.1rem;
  }
}

.c-megamenu-nav {
  $c: &;

  position: relative;
  display: flex;
  flex-wrap: nowrap;

  &__item {
    flex: 1 1 25%;
    padding-left: 2.2rem;

    &:not(:first-child) {
      border-left: 0.1rem solid $grey-800;
    }
  }

  &__link {
    color: $grey-300;
    font-size: $font-size-xs;
    font-weight: 500;
    letter-spacing: 0.078rem;
    line-height: 1.8rem;
    text-decoration: none !important;

    /* specific stuff allowing to clamp ellipse overflowing texts
    and keep them one one line */
    /* stylelint-disable */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* stylelint-enable */

    &:hover,
    &:focus {
      color: $primary-light;
    }

    > span {
      padding: 0.3rem;
      margin-left: 0.5rem;
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__title {
    display: block;
    padding-top: 0.4rem;
    margin: 0;
    margin-bottom: 1.8rem !important;
    color: $grey-300;
    font-size: $global-font-size;
    font-weight: 900;
    letter-spacing: 0.14rem;
    line-height: 1;
    text-decoration: none !important;
    text-transform: uppercase;
  }

  &__wrapper {
    width: 100%;
    padding: 0 2.2rem;
    margin-top: 2.7rem;
  }

  &__btn {
    width: 100%;
    padding: 2.2rem 6rem;
    margin-top: 2.7rem;
  }

  &--brands {
    #{$c}__item:first-child {
      flex: 1 1 30%;
    }
  }

  &__list-wrapper {
    display: flex;
    width: 100%;

    > * {
      flex-grow: 1;
    }
  }
}

.c-megamenu-products {
  padding: 0 2.2rem 0 0;

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -1.1rem;
    list-style-type: none;
  }

  &__item {
    padding: 0 1.1rem;
    margin-bottom: 2rem;
  }
}
</style>
