<template>
  <div class="c-modal-simple">
    <div v-if="!disableTitle" class="c-modal-simple__txt">
      <slot name="title" />
    </div>
    <slot name="content"></slot>
    <VButton v-bind="additionnalPropsButton" @click="hideModal(modalName)">
      <slot name="button" />
    </VButton>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import VButton from '@/components/atoms/global/button/VButton'

export default {
  components: { VButton },
  props: {
    modalName: {
      type: String,
      required: true
    },
    additionnalPropsButton: {
      type: Object,
      default: () => {}
    },
    disableTitle: Boolean
  },
  methods: {
    ...mapMutations({
      hideModal: 'modal/HIDE_MODAL',
    }),
  }
}
</script>

<style lang="scss" scoped>
.c-modal-simple {
  padding: 8.1rem 7rem 7.6rem;
  text-align: center;

  &__txt {
    margin-bottom: 6rem;
  }
}
</style>
