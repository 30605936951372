export const CMS_SLUG = {
  laboutiqueofficielle: {
    deliveryReturn: 'livraisons-et-retours',
    cgv: 'cgv',
    faq: 'faq',
    jobs: 'jobs',
    promoCode: 'code-promo',
    legalNotices: 'mentions-legales',
    returnConditions: 'conditions-de-retour'
  },
  ryses_es: {
    deliveryReturn: 'entregas-y-devoluciones',
    cgv: 'condiciones-generales-de-venta',
    faq: 'ayuda',
    jobs: 'jobs',
    promoCode: 'codigo-promocional',
    legalNotices: 'aviso-legal',
    returnConditions: 'condiciones-de-devolucion'
  },
  ryses_it: {
    deliveryReturn: 'consegna-e-reso',
    cgv: 'condizioni-generali-di-vendita',
    faq: 'assistenza',
    jobs: 'jobs',
    promoCode: 'codice-promozionale',
    legalNotices: 'note-legali',
    returnConditions: 'condizioni-di-reso'
  },
  ryses_be: {
    deliveryReturn: 'levering-en-retour',
    cgv: 'algemene-voorwaarden',
    faq: 'hulp',
    jobs: 'vacatures',
    promoCode: 'code-promo',
    legalNotices: 'juridische-bepalingen',
    returnConditions: 'retourvoorwaarden'
  },
  ryses_de: {
    deliveryReturn: 'lieferungen-und-rucksendungen',
    cgv: 'allgemeine-geschaftsbedingungen',
    faq: 'hilfe',
    jobs: 'jobs',
    promoCode: 'aktionscode',
    legalNotices: 'impressum',
    returnConditions: 'ruckgabebedingungen'
  },
  ryses_com: {
    deliveryReturn: 'delivery-and-returns',
    cgv: 'terms-and-conditions',
    faq: 'faqs',
    jobs: 'jobs',
    promoCode: 'discount-code',
    legalNotices: 'legal-notices',
    returnConditions: 'returns-policy'
  }
}
