import locale77526775 from '../../lang/fr.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: false,
}
export const options = {
  vueI18n: {"fallbackLocale":"fr"},
  vueI18nLoader: false,
  locales: [{"code":"fr","iso":"fr-FR","file":"fr.js","domain":"www.laboutiqueofficielle.com"},{"code":"es","iso":"es-ES","file":"es.js","domain":"www.ryses.es"},{"code":"it","iso":"it-IT","file":"it.js","domain":"www.ryses.it"},{"code":"nl","iso":"nl-BE","file":"nl.js","domain":"www.ryses.be"},{"code":"de","iso":"de-DE","file":"de.js","domain":"www.ryses.de"},{"code":"en","iso":"en-IE","file":"en.js","domain":"www.ryses.com"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: true,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: false,
  pages: {"marques":{"fr":"/marques","es":"/marcas","it":"/marche","nl":"/merken","de":"/marken","en":"/brands"}},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"fr","iso":"fr-FR","file":"fr.js","domain":"www.laboutiqueofficielle.com"},{"code":"es","iso":"es-ES","file":"es.js","domain":"www.ryses.es"},{"code":"it","iso":"it-IT","file":"it.js","domain":"www.ryses.it"},{"code":"nl","iso":"nl-BE","file":"nl.js","domain":"www.ryses.be"},{"code":"de","iso":"de-DE","file":"de.js","domain":"www.ryses.de"},{"code":"en","iso":"en-IE","file":"en.js","domain":"www.ryses.com"}],
  localeCodes: ["fr","es","it","nl","de","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "f",
  3: "r",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "a",
  15: "n",
  16: "g",
  17: "/",
  18: "f",
  19: "r",
  20: ".",
  21: "j",
  22: "s",
  23: "\"",
  24: ",",
  25: "\"",
  26: "e",
  27: "s",
  28: ".",
  29: "j",
  30: "s",
  31: "\"",
  32: ":",
  33: "\"",
  34: ".",
  35: ".",
  36: "/",
  37: "l",
  38: "a",
  39: "n",
  40: "g",
  41: "/",
  42: "e",
  43: "s",
  44: ".",
  45: "j",
  46: "s",
  47: "\"",
  48: ",",
  49: "\"",
  50: "i",
  51: "t",
  52: ".",
  53: "j",
  54: "s",
  55: "\"",
  56: ":",
  57: "\"",
  58: ".",
  59: ".",
  60: "/",
  61: "l",
  62: "a",
  63: "n",
  64: "g",
  65: "/",
  66: "i",
  67: "t",
  68: ".",
  69: "j",
  70: "s",
  71: "\"",
  72: ",",
  73: "\"",
  74: "n",
  75: "l",
  76: ".",
  77: "j",
  78: "s",
  79: "\"",
  80: ":",
  81: "\"",
  82: ".",
  83: ".",
  84: "/",
  85: "l",
  86: "a",
  87: "n",
  88: "g",
  89: "/",
  90: "n",
  91: "l",
  92: ".",
  93: "j",
  94: "s",
  95: "\"",
  96: ",",
  97: "\"",
  98: "d",
  99: "e",
  100: ".",
  101: "j",
  102: "s",
  103: "\"",
  104: ":",
  105: "\"",
  106: ".",
  107: ".",
  108: "/",
  109: "l",
  110: "a",
  111: "n",
  112: "g",
  113: "/",
  114: "d",
  115: "e",
  116: ".",
  117: "j",
  118: "s",
  119: "\"",
  120: ",",
  121: "\"",
  122: "e",
  123: "n",
  124: ".",
  125: "j",
  126: "s",
  127: "\"",
  128: ":",
  129: "\"",
  130: ".",
  131: ".",
  132: "/",
  133: "l",
  134: "a",
  135: "n",
  136: "g",
  137: "/",
  138: "e",
  139: "n",
  140: ".",
  141: "j",
  142: "s",
  143: "\"",
  144: "}",
}

export const localeMessages = {
  'fr.js': () => Promise.resolve(locale77526775),
  'es.js': () => import('../../lang/es.js' /* webpackChunkName: "lang-es.js" */),
  'it.js': () => import('../../lang/it.js' /* webpackChunkName: "lang-it.js" */),
  'nl.js': () => import('../../lang/nl.js' /* webpackChunkName: "lang-nl.js" */),
  'de.js': () => import('../../lang/de.js' /* webpackChunkName: "lang-de.js" */),
  'en.js': () => import('../../lang/en.js' /* webpackChunkName: "lang-en.js" */),
}
