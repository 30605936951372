<!-- @format -->
<template>
  <SimpleModal name="outlet" @close="close" @open="open">
    <div class="c-modal-email">
      <h1 class="c-modal-email__title">
        {{ $t('components.molecules.modal.outlet.title') }}
      </h1>
      <FormMessage v-if="message" :success="false" @hide="message = ''">
        {{ message }}
      </FormMessage>

      <form class="c-modal-email__form" novalidate @submit.prevent="submit">
        <InputForm
          id="email"
          v-model="email"
          :error="error"
          :label="$t('components.molecules.modal.outlet.email')"
          type="email"
          @input="clearError"
        />
        <VButton type="submit" primary :loading="loading">
          {{ $t('components.molecules.modal.outlet.submit') }}
        </VButton>
      </form>
      <p class="c-modal-email__info">
        {{ $t('components.molecules.modal.outlet.info') }}
      </p>
    </div>
  </SimpleModal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

import FormMessage from '@/components/atoms/form/message/FormMessage'
import InputForm from '@/components/atoms/form/input/VInput'
import SimpleModal from '@/components/atoms/modal/SimpleModal'
import VButton from '@/components/atoms/global/button/VButton'
export default {
  name: 'OutletModal',
  components: {
    FormMessage,
    InputForm,
    SimpleModal,
    VButton,
  },
  data() {
    return {
      loading: false,
      error: false,
      email: null,
      message: null,
    }
  },
  computed: {
    ...mapState({
      popinOutlet: (state) => state.popinOutlet,
    }),
  },
  mounted() {
    if (this.popinOutlet) {
      this.showModal('outlet')
    }
  },
  methods: {
    ...mapMutations({
      showModal: 'modal/SHOW_MODAL',
    }),
    ...mapActions({
      togglePopinOutlet: 'togglePopinOutlet',
      setOutletAccessAllowed: 'setOutletAccessAllowed',
    }),

    async submit() {
      this.loading = true
      try {
        await this.$isOutletAccessAllowed(this.email)
        this.$cookies.set('outletEmail', this.email, {
          path: '/',
          maxAge: 60 * 60 * 24 * 30,
        })
        this.setOutletAccessAllowed(true)
        this.togglePopinOutlet(false)
        document.querySelector('html').classList.remove('has-overflow')
        this.$router.push({ path: this.localePath('/outlet') })
      } catch (e) {
        this.error = true
        this.message = this.$t('components.molecules.modal.outlet.error')
      } finally {
        this.loading = false
      }
    },
    clearError() {
      this.error = false
    },
    open() {
      if (
        this.$store.state.pagename.startsWith('index') ||
        this.$store.state.pagename.startsWith('tp-name')
      ) {
        this.$store.commit('ui/UPDATE_TRANSPARENT_HEADER', true)
      }
    },
    close() {
      this.togglePopinOutlet(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-modal-email {
  width: 100%;
  max-width: 41rem;
  padding: 8.1rem 0 7.6rem;
  margin: 0 auto;

  &__title {
    margin: 0;
    margin-bottom: 2.4rem;
    color: $grey-300;
    font-size: $font-size-lg;
    font-weight: 700;
    line-height: 1;
    text-align: center;

    @include mq($until: tablet) {
      font-size: $font-size-xl-mobile;
    }
  }

  &__form {
    width: 100%;
    max-width: 24.4rem;
    margin: 0 auto;
    margin-bottom: 5.2rem;

    .c-input {
      margin-bottom: 3rem;

      @include mq($until: tablet) {
        margin-bottom: 2.6rem;
      }
    }

    .c-btn {
      width: 100%;
    }

    @include mq($until: tablet) {
      max-width: 100%;
      margin-bottom: 4.6rem;
    }
  }

  &__info {
    color: $grey-300;
    font-size: $font-size-sm;
    font-weight: 400;
    text-align: center;

    @include mq($until: tablet) {
      font-size: $font-size-sm-mobile;
    }
  }
}
</style>
