<template>
  <div
    class="o-layout"
    :class="{'c-login-step-one-basket': comeFromStepOneOfBasket}"
  >
    <HeaderTunnel v-if="comeFromStepOneOfBasket" />
    <div class="o-login-layout">
      <div class="o-login-layout__content">
        <Logo v-if="!comeFromStepOneOfBasket" center />
        <Timeline v-if="comeFromStepOneOfBasket" :current="2" special-step />
      </div>
      <div>
        <ChristmasBanner
          v-if="displayChristmasBanner"
          :content-to-display="christmasBannerContentToDisplay"
        />
        <nuxt />
      </div>
    </div>
    <FooterCredits special>
      &copy; {{ year }} - {{ $t('components.molecules.footer.credits.copyright') }}
    </FooterCredits>
    <ModalLocalStorage />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import dayjs from 'dayjs'
import christmasBanner from '@/mixins/christmasBanner'
import ChristmasBanner from '@/components/molecules/banner/christmas-banner/ChristmasBanner.vue'
import HeaderTunnel from '@/components/organisms/layout/header-tunnel/HeaderTunnel'
import Logo from '@/components/atoms/global/logo/Logo'
import Timeline from '@/components/atoms/tunnel/timeline/Timeline'
import FooterCredits from '@/components/atoms/footer/footer-credits/FooterCredits'
import ModalLocalStorage from '@/components/organisms/home/modal-localstorage/ModalLocalStorage.vue'
export default {
  name: 'Login',
  components: {
    ChristmasBanner,
    HeaderTunnel,
    Timeline,
    Logo,
    FooterCredits,
    ModalLocalStorage
  },
  mixins: [christmasBanner],
  data () {
    return {
      year: dayjs().format('YYYY')
    }
  },
  computed: {
    ...mapState({
      localStorageWarnAccepted: state => state.localStorageWarnAccepted,
      comeFromStepOneOfBasket: state => state.comeFromStepOneOfBasket
    })
  },
  mounted () {
    if (!this.$localStorage && !this.localStorageWarnAccepted) {
      this.showModal('localStorage')
    }
  },
  beforeDestroy () {
    this.setComeFromStepOneOfBasket(false)
  },
  methods: {
    ...mapMutations({
      showModal: 'modal/SHOW_MODAL',
    }),
    ...mapActions({
      setComeFromStepOneOfBasket: 'setComeFromStepOneOfBasket'
    })
  }
}
</script>

<style lang="scss" scoped>
.o-login-layout {
  width: 100%;
  max-width: 136.6rem;
  min-height: calc(100vh - 3.5rem);
  padding: 4.3rem 4.3rem 12rem;
  margin: 0 auto;
  background-color: $grey-1400;

  @include mq($until: tablet) {
    padding: 0;
    padding-top: 1.3rem;
  }

  &__content {
    margin-bottom: 4.2rem;

    @include mq($until: tablet) {
      margin-bottom: 1.4rem;
    }
  }
}

.c-login-step-one-basket {
  .o-login-layout {
    padding-top: 0;

    &__content {
      margin-bottom: 0;
    }
  }
}
</style>
