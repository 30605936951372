var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isModalPrerendered)?_c('transition',{attrs:{"name":"fade"},on:{"before-enter":_vm.onEnter,"after-enter":_vm.emitOpened,"before-leave":_vm.onLeave,"after-leave":_vm.emitClosed}},[(_vm.isModalOpened[_vm.name])?_c('div',{class:[
        'c-overlay',
        { noPadding: _vm.hasNoPadding },
        { noPaddingNoOverflow: _vm.hasNoPaddingAndNoOverflow },
        { noPaddingNoOverflowMobile: _vm.hasNoPaddingAndNoOverflowOnMobile },
      ],on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.hideModal(_vm.name)}}},[_c('div',{class:[`c-modal c-modal--${_vm.name}`],style:({ width: _vm.width, height: _vm.height })},[_c('button',{staticClass:"c-modal__close",on:{"click":function($event){return _vm.hideModal(_vm.name)}}},[_vm._t("close-icon",function(){return [_c('CloseIcon')]})],2),_vm._v(" "),_vm._t("default")],2)]):_vm._e()]):_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalOpened[_vm.name]),expression:"isModalOpened[name]"}],class:[
      'c-overlay',
      { noPadding: _vm.hasNoPadding },
      { noPaddingNoOverflow: _vm.hasNoPaddingAndNoOverflow },
      { noPaddingNoOverflowMobile: _vm.hasNoPaddingAndNoOverflowOnMobile },
    ],on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.hidePrerenderedModal.apply(null, arguments)}}},[_c('div',{class:[`c-modal c-modal--${_vm.name}`],style:({ width: _vm.width, height: _vm.height })},[_c('button',{staticClass:"c-modal__close",on:{"click":_vm.hidePrerenderedModal}},[_vm._t("close-icon",function(){return [_c('CloseIcon')]})],2),_vm._v(" "),_vm._t("default")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }